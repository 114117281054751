@import '../../../../scss/theme-bootstrap';

// new for 2018 Creative Refresh

.product-shade-picker-v2 {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  position: relative;
  width: 100%;
  &__shade-item {
    height: 38px;
    margin: 5px;
    position: relative;
    width: 38px;
  }
  &__shade {
    @include cr18-shade-swatch;
    box-shadow: inset 0 0 0 5px $color-white;
    height: 38px;
    width: 38px;
    &:after {
      content: ' ';
      display: block;
      height: 28px;
      left: 5px;
      margin: 0;
      position: absolute;
      top: 5px;
      width: 28px;
    }
    &.inv-status--sold-out:after,
    &.inv-status--not-shoppable:after {
      @include cr18-shade--disabled;
    }
  }
}
